// Colors #878d99;
$default: oldlace;
$primary: #409eff;
$sucess: #67c23a;

// Some Reset
html,
body {
	width: 100%;
	height: 100%;
	padding: 0;
	overflow: hidden;
	font-family: "Open Sans", sans-serif;
}

body {
	display: flex;
	justify-content: center;
	align-items: center;
}

.container {
    display: flex;
    align-items: stretch;; 
}

.container:ion-img {
	/*width: 450px;
    height: 376px;*/
    display: flex;
    align-items: stretch;; 
}

.ionthumbbigger {
    min-width: 48px;
    display: flex;
    align-self: center;
    align-content: center;  
    flex-direction: column;
    display: flex;
    align-items: stretch;; 
}   

// Change component color
@mixin states($color: $default) {
	border-color: $color;
	color: rgba(255, 255, 255, 0.9);
	background: $color;

	&:hover {
		border-color: $color;
		color: rgba(255, 255, 255, 0.6);
	}
	&:active {
		color: rgba(255, 255, 255, 0.9);
		border-color: darken($color, 10%);
	}
}

// Collapse / Expand Component
details {
	@include states($default);
	border: 1px solid;
	cursor: pointer;
	transition: background 0.3s;
	border-radius: 4px;
	min-height: 28px;
	max-height: 600px;
	transform-origin: top center;
	transform: rotate(0.1deg);
	transition: all 0.3s;

	::-webkit-details-marker {
		display: none;
	}

	+ details {
        margin-top: 2px;
        border: 4px dotted oldlace;
	}

	p {
		color: #fefefe;
		line-height: 1.7;
		margin: 4px 0 0;
		padding: 0 10px 4px;
	}

	// Primary Style
	&.primary {
		@include states($primary);
	}

	// Success Style
	&.success {
		@include states($sucess);
	}

	// Square Style
	&.square {
		border-radius: 0;
	}

	&[open] {
		transition: all 0.6s;
		min-height: 38px;
		max-height: fit-content;
	}
}

summary {
	outline: none;
	font-size: 14px;
    padding: 0px;
    max-height: 42px;
	
	&:selection {
		background: transparent;
	}


	.close {
		display: none;
		
		[open] & {
			display: inline;
		}
	}

	.open {
		display: inline;

		[open] & {
			display: none;
		}
	}

	[open] & {
		display: inline;
	}

	&:after {
		margin-top: 0px;
		/*content: "➕";*/
		float: left;
		margin-right: 26px;
		text-align: left;
		font-size: 11px;
		
		[open] & {
			padding: 0 0 2px 0;
			/*content: "➖";*/
		}
	}
}

.sborder {
	border: 2px solid wheat;
	padding: 4px;
	margin-left: 4px;
}
