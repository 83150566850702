.my-custom-class {
    --background: oldlace;
    /* height: 40%;
    top: 20%; */
    /* position: absolute; 
    display: block;   */
  }
.LoginCard {
  background-blend-mode: hard-light;
  font-size: 20px;
  font-weight: bold;
  color: navy;
  height: 96%;
  padding: 60px 30px 30px 30px;

}
.logininputozel {
  padding: 10px 10px 20px 10px;
  font-size: 24px  !important;
  background-color: white !important;
  border: 0.4px dotted red !important;
  max-height: 28px  !important;
}

#cmdGiris {
  width: 200px !important;
  height: 62px !important;
 
}