ion-content ion-toolbar {
  --background: translucent;
}

.genislogo{
  height: 66px;
  width: 260px;
}


.lblsegment {
  font-size: larger;
  color: brown;
  padding: 14px;

}

.txtsegment {
  font-size: larger;
  font-weight: bold;
}

img, ion-img {
  padding: 3px;
}

ion-card {
  border: 2px dashed gainsboro;
  padding: 10px 10px 20px;
}

.inputozel {
  border: 0.3px solid navy;
  height: 28px;
}

.propdetailhead {
  /*padding-left: 14px;*/
  margin-left: 18px;
}

.propdetailsdiv{
  /*padding: 1px 10px 10px 10px;*/
  padding: 2px 2px 2px 2px;
}
.ionthumbpic:focus, .ionthumbpic:active, .ionthumb:active {
  border: 10px dotted red !important;
}
.ionthumb {
  border: 1px dotted navy !important;
  border-radius: 4px;
}
.ionthumb:hover {
  border: 1px solid red !important;
}

ion-content ion-toolbar {
  --background: translucent;
}

button {
  height: 32px;
}

#propidsearch {
  height: 22px;
  margin-top: -4px;
  width: 46px;
}

ion-content {
  background-color: #fff;
  padding:15px;
}

.radio .radio-icon {
  visibility: visible !important;
}

.radio .radio-icon:before {
  content: "" !important;
  border: 2px solid black !important;
  width: 24px !important;
  height: 24px !important;
  border-radius: 50% !important;
  overflow: hidden !important;
}

.radio .radio-icon:after {
  content: "" !important;
  position: absolute !important;
  right: 20px !important;
  top: 22px !important;
  background: black !important;
  width: 12px !important;
  height: 12px !important;
  border-radius: 50% !important;
  overflow: hidden !important;
  transition: -webkit-transform .28s cubic-bezier(0.420, 0.000, 0.000, 1.650);
  transition: transform .28s cubic-bezier(0.420, 0.000, 0.000, 1.650);
  -webkit-transform: scale(0);
  transform: scale(0);
}

.radio.item-radio > input[type=radio]:checked ~ .radio-icon:after {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.radio .item-content {
  background-color: #fff;
  margin: 0;
  padding-right: 50px;
  padding-left: 0px;
}

.radio.item-radio > input[type=radio]:checked ~ .item-content {
  background-color: #fff;
}

.radio-inline.item {
  display: inline-block;
  border: none;
  margin: 0;
  height: 50px;
}

.radio-blue .radio-icon:after {
  background: #2196F3 !important;
}

.radio-blue .radio-icon:before {
  border-color: #2196F3 !important;
}

.radio-teal .radio-icon:after {
  background: #009688 !important;
}

.radio-teal .radio-icon:before {
  border-color: #959e9d !important;
}

.radio-gray .radio-icon:after {
  background: #B6B6B6 !important;
}

.radio-gray .radio-icon:before {
  border-color: #B6B6B6 !important;
}


/* IonLabel {
  font-size: 7px;
}

IonCheckbox {
  align-self: left;
  justify-content: start;
  background-color: blueviolet;
} */

ion-button {
  max-height: 28px !important;
}
ion-radio {
  color: navy;
  margin-left: 4px;
  padding-left: 4px;
  margin-top: 4px;

  /* background-color: oldlace;
  padding: 2px 2px 2px 0px;
  
  outline-color: blueviolet;
  display: inline;
 border-radius: 400px;*/
}
ion-label {
  background-origin: padding-box;
  color: navy;
  background-color: white;
  margin-left: 6px; 
}

.propHeader{
  font-size: medium;
  font-weight: bolder;
  min-width: 120vh;
  color: brown;
}

.list .item-radio .item-content {
  background-color: black;
  color: white;
}
.list .item-radio .radio-icon {
  background-color: black;
  color: white;
}

.item-radio input:checked + .radio-content .item-content {
  background-color: black;
}

ion-input, ion-datetime{
  background-color:  lightblue !important;
  color: navy !important;
    border: 0.6px dotted pink;
      border-radius: 20px;
      max-height: 22px;
      font-size: 14px !important;
  /*  background-color: white;   */
}

ion-datetime {
  background-color:  white !important;
  padding-bottom: 0px;  
  color: navy;
  min-height: 32px !important;
  border: 0.9px dotted brown;
}


ion-input:focus{
  background-color:  gold !important;
}
/*ion-input:blur{
  background-color:  gold !important;
}*/
ion-label{
  font-size: 12px;
}


 #r_Kategori,  
#manzara, #muhit, #icOzellik, #customInputFiyat, #customInputDurumu, #customInputBm2
,#customInputNm2,
#txtMahalle,#txtSehir,#txtEmlakSahibi,#txtAdres, #r_TapuDurumu, #r_IsitmaTipi , 
#r_OdaSayisi ,#r_KatDetay ,#engelliyeUygun, #Muhit, 
#IcOzellik {
  border: 0.6px dotted navy;
  padding: 2px 2px 2px 2px;
  border-radius: 20px;
  background-color: oldlace;
}
#r_KonutTipi, #cephe,#r_KiralikSatilik, #r_BulunduguKat, #r_KatSayisi,#binaYasi,
#r_BinaYasi,  #r_Cephe, #Manzara, #Ulasim , #DisOzellik  {
  border: 0.6px dotted navy;
  padding: 2px 2px 2px 2px;
  border-radius: 20px;
  background-color: whitesmoke;
}
#customInputFiyat, #customInputAidat, #customInputDurumu, #customInputBm2,#customInputNm2,
#txtMahalle,#txtSehir,#txtEmlakSahibi,#txtAdres,#dtpBosTarih{
  /* max-width: 82px;
  min-width: 62px; */
  max-height: 28px;
  /* margin-left: 12px; */
}

#customInputBm2,#customInputNm2{
  /* max-width: 62px;
  min-width: 52px; */
  border-radius: 40px;
}
#dtpBosTarih{
  /* max-width: 132px;
  min-width: 132px; */
  border-radius: 50px;

}
#txtAdres{
  /* max-width: 442px;
  min-width: 312px; */
}
#txtEmlakSahibi{
  /* max-width: 102px;
  min-width: 102px; */
}

/*
IonInput{
  background-color: orange !important;
}
.navite-input{
  background-color: orange !important;
}
*/


ion-toast{
  min-height: 450px !important;
  color: white !important;
}

.radioLabel {
    font-size: small !important;
    color: black;
}

ion-checkbox {
  margin: 2px !important;
  
}

.cbLabel {
  min-width: 160px !important;
  color: navy;
  width: 160px !important;
}

.datetime-text, #datetime-text, datetime-text{
  font-size: larger !important;
  color: red !important;
}

.txtemlakno, #txtemlakno {
  height: 136px !important;
  border: 2px solid red  !important;;
  font-size: larger;
  font-weight: bold;
  text-align: center;
  max-width: 140px;
}

ion-button {
  /* border-radius: 10px !important;
  border: 1px solid navy;; */
  background: transparent;
}

ion-input {
  font-size: medium !important;
  font-weight: bold !important;

   /* margin: 0 .25rem;
min-width: 125px;
  border: 1px solid #eee;
  border-left: 1px solid;
  border-radius: 12px;
  transition: border-color .5s ease-out;*/

}
/*
ion-input:optional {
  border-left-color: #999;

}
ion-input:required {
  border-left-color: palegreen;

}
ion-input:invalid {
  border-left-color: salmon;

}
 ion-button:disabled {
  background-color: grey !important;
  color: grey !important;
  border: 2px solid red;;
} */

#propidsearch:hoover {
  background-blend-mode: color-burn !important;
  
}


.item-has-focus:focus-within{
 /* background: white !important;   */
  --highlight-background: gold;
}



#vvaadtext, .native-textarea, .textarea, ion-datetime , #yyetkitext{
  background-color: lightblue !important;
  border-radius:10px !important;
  font-size: 18px;
  font-weight: bolder;
}

ion-datetime {
  font-size: 18px !important;
}

#my-node, #my-nodeyetki{
  background-blend-mode: color-burn !important;
  font-size: 15px;
  line-height: 20px;
  padding: 10px 10px 10px 10px;
  width: 798px;
  height: 1600px;
  word-wrap: normal;
  word-break: keep-all;
  justify-content: space-between;
  font-weight: bolder;
  font-weight: normal !important;
}

.baslikLbl {
  font-weight: bold;
}
.gridcollabel {
  color: navy;
  /* max-height: 20px; */
  text-decoration-style: dotted;
  text-underline-position: below;
}
.gridcolval {
  color: black;
  font-weight: normal;
  /* max-height: 20px; */
}

.gridcollabel2 {
  color: navy;
  /* max-height: 28px; */
  text-decoration-style: dotted;
  text-underline-position: below;
}
.gridcolval2 {
  color: black;
  font-weight: normal;
  /* max-height: 28px; */
}

.lblsegment.detaybutton{
  background: transparent;
  border: 2px solid oldlace;
  --box-shadow: none;
  border-style: outset;
  text-justify: auto;  /*  padding-top: 1px !important;  */
  padding-top: 4px !important; 
  border-radius: 16px;
}
ion-segment-button {
 /*  background-color: red !important; 
  border: 2px solid oldlace !important;
  --box-shadow: none !important;
  border-style: outset !important;


  border-radius: 16px !important; */

  /*   text-justify: auto !important; padding-top: 1px !important;  */ 
  max-width: 62px !important;
  min-width: 52px !important;
}

ion-segment-button.active {
  border: 4px solid oldlace !important;
  --box-shadow: none !important;
  border-style: outset !important;
  text-justify: auto !important; 

  border-radius: 14px !important;


}
ion-segment {
  background-color: oldlace !important;
  padding: 0px 10px 0px 10px;
}
ion-segment ion-segment-button ion-label {
  background-color: oldlace !important;
  font-weight: bolder;
  text-align: left !important;
  align-self: left !important;
  padding: 0 0 0 0 !important;
  margin-left: -28px !important;
}


.ionBtnFooter {
  /* outline-style: outset !important;
  fill: 'outline' !important; */
  border: none !important;
  border-color: white !important;

}


  /* 
 

 
  
  min-width: 320px !important; 
.smallimages 
 
  display: flex;
  flex-direction: column-reverse;
  flex-flow: wrap;
 min-width: 200px !important;
  max-width: 200px !important;
  align-items: top;
  align-self: top;
  left: auto;
  right: auto;
justify-self: center;
justify-content: center;{*/

  /*    max-width: 140px !important;   flex-wrap: wrap; flex-flow: column wrap;
    justify-content: space-around !important;
  flex-flow: column wrap;  align-self: auto;  flex-direction: column-reverse;
  */
 
  /*flex-start | flex-end | center | space-between | space-around | space-evenly | start | end | left | right ... + safe | unsafe;*/



 
