

.searchbarplus {
    padding: 0px;
    height: 42px !important;
    background: oldlace;
    font-weight: bold;
}


#searchbarplus1, #searchbarplus3{
    padding: 0px !important;
    color: navy !important;
    height: 62px !important;
     /* border: 1px solid red !important;   */
    font-weight: bold;
    box-shadow: inset;
}

/* to be attached onto ion-item when needed */
.hidden { 
    display: none;
  } 
  