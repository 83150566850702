/* Custom Icon Segment MD */
.md .custom-icon {
    --indicator-color: lightgray;
  }
  
  .md #segment-facebook {
    --background-hover: rgba(59, 89, 153, 0.04);
    --color-activated: #3b5999;
    --color-checked: #3b5999;
  }
  
  .md .segment-instagram {
    --background-hover: rgba(228, 64, 95, 0.04);
    --color-activated: #e4405f;
    --color-checked: #e4405f;
  }
  
  .md .segment-slack {
    --background-hover: rgba(58, 175, 133, 0.04);
    --color-activated: #3aaf85;
    --color-checked: #3aaf85;
  }
  
  /* Custom Icon Segment iOS */
  .ios .custom-icon ion-segment-button {
    --border-width: 0;
  }
  
  .ios #segment-facebook {
    --color: #3b5999;
    --color-checked: #ffffff;
    --background-hover: rgba(59, 89, 153, 0.1);
    --background-activated: rgba(59, 89, 153, 0.16);
    --background-checked: #3b5999;
  }
  
  .ios .segment-instagram {
    --color: #e4405f;
    --color-checked: #ffffff;
    --background-hover: rgba(228, 64, 95, 0.1);
    --background-activated: rgba(228, 64, 95, 0.16);
    --background-checked: #e4405f;
  }
  
  .ios .segment-slack {
    --color: #3aaf85;
    --color-checked: #ffffff;
    --background-hover: rgba(58, 175, 133, 0.1);
    --background-activated: rgba(58, 175, 133, 0.16);
    --background-checked: #3aaf85;
  }
  
  #custom-icon ion-icon {
    font-size: 44px;
  }
  ion-segment {
    margin-bottom: 5px;
  }
  
  .border-radius-on-button ion-segment-button {  
    --background: lightpink;
    --background-checked: lightblue;
    --border-radius: 50%;
  }
  
  .fix-ripple ion-segment-button {
    --border-radius: 20px;
    --indicator-transition: none;
    
    overflow: hidden;
  }
  
  .parent-border-radius {
    background: #e5e5e5;
    
    border-radius: 15px 15px 0 0;
  }
  
  /* This is added for the iOS segment indicator */
  .parent-border-radius ion-segment-button:first-of-type {
    --border-radius: 15px 0 0 0;
  }
  
  .parent-border-radius ion-segment-button:last-of-type {
    --border-radius: 0 15px 0 0;
  }